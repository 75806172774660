.Product-Card {
    width: 200px;
    padding: 15px;
    padding-bottom: 30px;
    border-radius: 25px;
    margin-bottom: 15px;
    margin-right: 0px;
}

.Product-Card:hover {
     background-color: #FFFFFF;
    box-shadow: inset 0px 4px 20px rgba(0, 0, 0, 0.08);   
}

.Product-Card img {
    margin-left: 0px;
    height: 170px;
    width: 170px;
    object-fit: cover;
}

.Product-Card-Price {
    color: #5096FF;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}

.Product-Card-Title {
    margin-top: 2.5px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 16px;
    color: #393939;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

}

.Product-Card-Brand {
    margin-top: 2.5px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    color: #393939;
}

.Product-Card-Info {
    margin-top: 2.5px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    color: #878787;
}

@media (min-width:580px) {
    .Product-Card {
      /* margin-right: 60px; */
    }
  }